import { LuShield, LuShieldCheck } from "react-icons/lu";
export const getRiskText = (translator, riskLevel)=>{
    switch(riskLevel){
        case "High":
            return translator("high");
        case "Moderate":
            return translator("moderate");
        case "Low":
            return translator("low");
        default:
            return "";
    }
};
export const getRiskProgressValue = (riskLevel)=>{
    switch(riskLevel){
        case "High":
            return 100;
        case "Moderate":
            return 75;
        case "Low":
            return 40;
        default:
            return 10;
    }
};
export const getRiskIcon = (riskLevel)=>{
    switch(riskLevel){
        case "High":
            return LuShield;
        case "Moderate":
            return LuShieldCheck;
        case "Low":
            return LuShieldCheck;
        default:
            return LuShield;
    }
};
export const getRiskColor = (riskLevel)=>{
    switch(riskLevel){
        case "High":
            return "#e8864f";
        case "Moderate":
            return "#f0c75b";
        case "Low":
            return "#4ead74";
        default:
            return "";
    }
};
