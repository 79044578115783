import { useTranslations } from "next-intl";
export const getProfitabilityPeriodName = (period)=>{
    const t = useTranslations("product.profitability-periods");
    switch(period){
        case "7d":
        case "14d":
        case "1m":
        case "3m":
        case "6m":
        case "1y":
            return t(period);
        default:
            return "";
    }
};
