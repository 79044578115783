export const DefaultTradingType = "spot";
export const GeneratedAccountIdPrefix = "generated_";
export const AccountPortfolioUSDLikeAssetKey = "USD-like";
export const accountHistoryTimeframes = [
    "weekly",
    "monthly",
    "yearly"
];
export const AverageTimeToCheckExchangePortfolio = 7000;
export const averageTimeToCheckExchangeCredentials = 5000;
