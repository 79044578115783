import { Flex, Text, Box, Button, Link } from "@chakra-ui/react";
import { useTranslations } from "next-intl";

export const SubscribeToNewsletterBanner: React.FC = () => {
  const t = useTranslations("home.newsletter")
  
  return (
    <Flex
      w="100%"
      p={{ base: '35px', md: '50px', xl: '50px' }}
      mb="10"
      direction={{ base: 'column', lg: 'row' }}
      overflow="hidden"
      bgSize="cover"
      bg="linear-gradient(15.46deg, rgb(7, 5, 35) 26.3%, rgb(10, 5, 89) 91.13%)"
      borderRadius={'16px'}
      position="relative"
      justifyContent={'space-between'}
    >
      <Flex flexGrow={'1'} direction={{ base: 'column', lg: 'row' }}>
        <Box my="auto" mx="auto" textAlign="center">
          <Text
            color={'white'}
            fontWeight="700"
            fontSize="xl"
            letterSpacing="0px"
          >
            {t('details')}
          </Text>
        </Box>
      </Flex>
      <Flex
        flexShrink={'1'}
        justifyContent="space-around"
        alignItems={'center'}
        mt={{ base: '4', lg: '0' }}
      >
        <Box>
          <Link
            href={t('substack-url')}
            target="_blank"
            rel="nofollow"
            data-ph-capture-attribute-button-role="subscribeToNewsletter"
            data-ph-capture-attribute-newsletter={t('substack-url')}
          >
            <Flex flexDirection={'column'} alignItems={'center'}>
              <Button
                variant="outline"
                color="white"
                _hover={{}}
                size={{ base: 'sm', sm: 'md' }}
                borderWidth="2px"
              >
                {t('subscribe')}
              </Button>
            </Flex>
          </Link>
        </Box>
      </Flex>
    </Flex>
  )
}
