import { GridItem, SimpleGrid, useBreakpointValue } from '@chakra-ui/react'
import { FC } from 'react'
import { StaticImageData } from 'next/image'
import { Exchange } from '@astrolab/back/exchange/exchange.entity'
import { ExchangeLogo } from '@astrolab/web/components/exchange/ExchangeLogo/ExchangeLogo'
import { LocalizedLink } from '@astrolab/web/common/LocalizedLink'
import { cleanupExchangeName } from '@lib/exchange'
import { useLocale } from 'next-intl'

export type ExchangeDetails = {
  label: string
  src: StaticImageData
}

const ExchangesLogos: FC<{
  exchanges: Exchange[]
  bg: string
}> = ({ exchanges, bg }) => {
  const locale = useLocale()
  const addPaddingLogo = useBreakpointValue({
    base: false,
    md: false,
    lg: exchanges.length % 3 == 1,
  })
  return (
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 3 }}
      spacing={{ base: '10px', md: '20px' }}
      w="100%"
      bg="linear-gradient(15.46deg, rgb(7, 5, 35) 26.3%, rgb(10, 5, 89) 91.13%)"
      borderRadius="20px"
      py="40px"
      mx="auto"
    >
      {exchanges.map((exchange: Exchange, index) => (
        <GridItem
          key={index}
          mx="auto"
          mb="10px"
          colSpan={index == exchanges.length - 1 && addPaddingLogo ? 3 : 'auto'}
        >
          <LocalizedLink
            href={`/${cleanupExchangeName(exchange.name || '')}-trading-bot`}
            locale={locale}
          >
            <ExchangeLogo
              exchange={exchange}
              colorMode="dark"
              quality={95}
              style={{ height: '5vh', width: 'auto' }}
              alt={`${exchange.name} logo meaning ${exchange.name} is supported by OctoBot`}
            />
          </LocalizedLink>
        </GridItem>
      ))}
    </SimpleGrid>
  )
}

export { ExchangesLogos }
