import { ArticleProps } from '@astrolab/web/components/news/ArticleProps'
import { VerticalArticle } from '@astrolab/web/components/news/VerticalArticle'
import { Box, Button, Flex, SimpleGrid } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { LocalizedLink } from '@astrolab/web/common/LocalizedLink'
import { useLocale } from 'next-intl'

const News: React.FC<{ articles: ArticleProps[]; callToAction: string }> = ({
  articles,
  callToAction,
}) => {
  const MotionBox = motion(Box)
  const locale = useLocale()

  return (
    <Flex align="center" direction="column" w="100%" mt="10px">
      <SimpleGrid
        minChildWidth={{ base: '270px', md: '350px' }}
        w="100%"
        spacing={{ base: '20px' }}
      >
        {articles.map((article: ArticleProps, index: number) => (
          <MotionBox
            key={index}
            whileHover={{
              scale: 1.05,
            }}
            transition={{ duration: 0.2 }}
          >
            <VerticalArticle article={article} mx="auto" />
          </MotionBox>
        ))}
      </SimpleGrid>
      <Box mt="6" textAlign="center">
        <LocalizedLink href="/blog" forcedLocale={true} locale={locale}>
          <Button
            variant="outline"
            fontWeight="500"
            fontSize="lg"
            mt="10px"
            py="20px"
            w={{ base: '200px', md: '300px' }}
            borderColor="brand"
          >
            {callToAction}
          </Button>
        </LocalizedLink>
      </Box>
    </Flex>
  )
}

export { News }
