import {
  Heading,
} from "@chakra-ui/react";

const StyledTitle: React.FC<{
  children: any, [rest: string]: any;
}> = ({ children, ...rest }) => {
  return (
    <Heading
      textAlign={{ base: "center" }}
      fontWeight="600"
      fontSize={{ base: "34px", md: "42px", lg: "37px", xl: "48px" }}
      mb="20px"
      w={{ base: "100%", md: "82%" }}
      mx="auto"
      {...rest}
    >
      {children}
    </Heading>
  );
}

export {StyledTitle}
