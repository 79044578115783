import { ArticleProps } from '@astrolab/web/components/news/ArticleProps'
import article1Banner from '/public/images/blog/how-to-create-your-tradingview-strategy-with-ai/trading-strategy-with-ai-in-5-minutes-using-octobot-cloud-and-free-tradingview-account.png'
import article2Banner from '/public/images/blog/what-are-octobot-rewards-and-how-to-get-them/octobot-rewards-get-rewarded-for-using-octobot-and-use-advanced-strategies.png'
import article3Banner from '/public/images/blog/making-octobot-more-accessible/octobot-plans-improvements.png'
import tradingArticle1Banner from '/public/images/blog/octobot-2-0-0-whats-new/octobot-2.0.0-annoucement-with-new-design-preview.png'
import tradingArticle2Banner from '/public/images/blog/one-click-cloud-deployment-with-octobot-1-0-9/octobot-1.0.9-ditigtalocean-1-click-deployment-custom-crypto-baskets.png'
import tradingArticle3Banner from '/public/images/blog/announcing-the-bitmart-and-octobot-partnership/bitmart-and-octobot-partnership.png'


export const getArticles = (locale: string, t: any): ArticleProps[] => {
  return [
    {
      title: t('article3'),
      description: '',
      link: `/${locale}/blog/making-octobot-more-accessible`,
      banner: article3Banner,
      author: 'The OctoBot team',
      date: new Date('2024-06-18T00:00:00'),
      difficultyLevel: 1,
      readingTime: 4,
    },
    {
      title: t('article1'),
      description: '',
      link: `/${locale}/blog/how-to-create-your-tradingview-strategy-with-ai`,
      banner: article1Banner,
      author: 'The OctoBot team',
      date: new Date('2024-11-08T00:00:00'),
      difficultyLevel: 1,
      readingTime: 4,
    },
    {
      title: t('article2'),
      description: '',
      link: `/${locale}/blog/what-are-octobot-rewards-and-how-to-get-them`,
      banner: article2Banner,
      author: 'The OctoBot team',
      date: new Date('2024-06-18T00:00:00'),
      difficultyLevel: 1,
      readingTime: 5,
    },
  ]
}



export const getTradingBotArticles = (locale: string, t: any): ArticleProps[] => {
  return [
    {
      title: t('article1'),
      description: '',
      link: `/${locale}/blog/octobot-2-0-0-whats-new`,
      banner: tradingArticle1Banner,
      author: 'The OctoBot team',
      date: new Date('2024-07-07T00:00:00'),
      difficultyLevel: 1,
      readingTime: 2,
    },
    {
      title: t('article2'),
      description: '',
      link: `/${locale}/blog/one-click-cloud-deployment-with-octobot-1-0-9`,
      banner: tradingArticle2Banner,
      author: 'The OctoBot team',
      date: new Date('2024-04-16T00:00:00'),
      difficultyLevel: 1,
      readingTime: 3,
    },
    {
      title: t('article3'),
      description: '',
      link: `/${locale}/blog/announcing-the-bitmart-and-octobot-partnership`,
      banner: tradingArticle3Banner,
      author: 'The OctoBot team',
      date: new Date('2024-09-08T00:00:00'),
      difficultyLevel: 1,
      readingTime: 3,
    }
  ]
}
