'use client'

import {
  Button,
  Flex,
  Icon,
  Text,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Link } from '@chakra-ui/next-js'
import { FaDiscord, FaTelegram } from 'react-icons/fa'
import { useTranslations } from 'next-intl'
import { discordLink } from '@constants'

const getDiscordLink = (joinDiscordText: string) => {
  return (
    <Link
      href={discordLink}
      target="_blank"
      rel="nofollow"
      data-ph-capture-attribute-join-community-link="discord"
    >
      <Flex flexDirection={'column'} alignItems={'center'}>
        <Icon
          as={FaDiscord}
          color={'white'}
          w="80px"
          h="80px"
          mb={{ base: '10px', md: '10px' }}
        />
        <Button
          variant="outline"
          color="white"
          _hover={{}}
          size={{ base: 'sm', sm: 'md' }}
          borderWidth="2px"
        >
          {joinDiscordText}
        </Button>
      </Flex>
    </Link>
  )
}

const JoinCommunity: React.FC<{
  discordCount: number
}> = ({ discordCount }) => {
  const t = useTranslations('home.community')
  return (
    <Flex
      w="100%"
      p={{ base: '35px', md: '50px', xl: '50px' }}
      mb="10"
      direction={{ base: 'column', lg: 'row' }}
      overflow="hidden"
      bgSize="cover"
      bg="linear-gradient(15.46deg, rgb(7, 5, 35) 26.3%, rgb(10, 5, 89) 91.13%)"
      borderRadius={'16px'}
      position="relative"
      justifyContent={'space-between'}
    >
      <Box textAlign={'center'} display={{ base: 'none', lg: 'flex' }}>
        {getDiscordLink(t('join-discord'))}
      </Box>
      <Flex flexGrow={'1'} direction={{ base: 'column', lg: 'row' }}>
        <Box my="auto" mx="auto" textAlign="center">
          <Text
            as="h2"
            color={'white'}
            fontWeight="700"
            fontSize="2xl"
            letterSpacing="0px"
          >
            {t('connect-with-the-community')}
          </Text>
          <Text
            color={'white'}
            fontWeight="500"
            fontSize="md"
            letterSpacing="0px"
          >
            {t('meet-more-than')}{' '}
            <Box as="span" fontWeight="700">
              {discordCount}
            </Box>{' '}
            {t('benefits')}
          </Text>
        </Box>
      </Flex>
      <Flex
        flexShrink={'1'}
        justifyContent="space-around"
        alignItems={'center'}
        mt={{ base: '4', lg: '0' }}
      >
        <Box textAlign={'center'} display={{ base: 'flex', lg: 'none' }}>
          {getDiscordLink(
            t(
              useBreakpointValue({
                base: 'join-discord-short',
                md: 'join-discord',
              })
            )
          )}
        </Box>
        <Box>
          <Link
            href="https://t.me/octobot_trading"
            target="_blank"
            rel="nofollow"
            data-ph-capture-attribute-join-community-link="telegram"
          >
            <Flex flexDirection={'column'} alignItems={'center'}>
              <Icon
                as={FaTelegram}
                color={'white'}
                w="75px"
                h="75px"
                mb={{ base: '10px', md: '10px' }}
                mt="5px"
              />
              <Button
                variant="outline"
                color="white"
                _hover={{}}
                size={{ base: 'sm', sm: 'md' }}
                borderWidth="2px"
              >
                {t(
                  useBreakpointValue({
                    base: 'join-telegram-short',
                    md: 'join-telegram',
                  })
                )}
              </Button>
            </Flex>
          </Link>
        </Box>
      </Flex>
    </Flex>
  )
}

export { JoinCommunity }
