'use client'

import { Button } from '@chakra-ui/react'
import { useLocale, useTranslations } from 'next-intl'
import { LocalizedLink } from '@astrolab/web/common/LocalizedLink'
import { defaultLocale } from '@astrolab/web/constants'
import { getStartedLinkUrl } from '@lib/url'

const GetStartedButton: React.FC<{
  isTradingBot?: boolean
  title?: string
  [rest: string]: any
}> = ({
  isTradingBot,
  title,
  ...rest
}) => {
  const t = useTranslations('global')
  const buttonTitle = title || (isTradingBot ? t('start-for-free') : t('get-started-for-free'))
  const locale = useLocale()
  const url = getStartedLinkUrl(locale, defaultLocale, isTradingBot || false)
  return (
    <LocalizedLink href={url} forcedLocale={isTradingBot} locale={locale}>
      <Button
        variant="brand"
        fontWeight="500"
        fontSize={{ base: '18px', md: '20px' }}
        py="20px"
        h="50px"
        w={{ base: 'fit-content', md: '300px' }}
        data-ph-capture-attribute-button-role={isTradingBot ? "tradingBotGetStarted": "getStarted"}
        {...rest}
      >
        {buttonTitle}
      </Button>
    </LocalizedLink>
  )
}

export { GetStartedButton }
