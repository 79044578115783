import { AccountPortfolioUSDLikeAssetKey } from "./exchange.entity";
import { ExchangeRepository } from "./exchange.repository";
import { getAssociatedBot, isAlreadyUsedAccount } from "../bot/bot.lib";
import { USD_LIKE_COINS, USD_LIKE_COINS_BY_EXCHANGE } from "../constants";
export const getExchangeFromId = (exchangeId, exchanges)=>{
    for (const exchange of exchanges){
        if (exchange.id == exchangeId) {
            return exchange;
        }
    }
    return undefined;
};
export const getExchangeFromName = (exchangeName, exchanges)=>{
    for (const exchange of exchanges){
        if (exchange.name == exchangeName) {
            return exchange;
        }
    }
    return undefined;
};
export const getExchangeFromInternalName = (exchangeInternalName, exchanges)=>{
    for (const exchange of exchanges){
        if (exchange.internal_name == exchangeInternalName) {
            return exchange;
        }
    }
    return undefined;
};
export const getExchangesFromInternalName = (exchangeInternalNames, exchanges)=>{
    return exchangeInternalNames == null ? void 0 : exchangeInternalNames.map((name, index)=>exchanges == null ? void 0 : exchanges.find((exchange)=>exchange.internal_name == name));
};
export const getExchangeIdsFromNames = (exchangeNames, exchanges)=>{
    return exchangeNames.map((exchangeName)=>{
        for (const exchange of exchanges){
            if (exchange.name == exchangeName) {
                return exchange.id != null ? exchange.id : undefined;
            }
        }
    });
};
export const isPremiumSupported = (exchange, tradingType)=>{
    var _exchange_premium;
    return ((_exchange_premium = exchange.premium) == null ? void 0 : _exchange_premium[tradingType]) == "yes" || false;
};
export const isPremiumPartiallySupported = (exchange, tradingType)=>{
    var _exchange_premium;
    return ((_exchange_premium = exchange.premium) == null ? void 0 : _exchange_premium[tradingType]) == "partial" || false;
};
export const isAvailable = (exchange, tradingType)=>{
    var _exchange_availability;
    return ((_exchange_availability = exchange.availability) == null ? void 0 : _exchange_availability[tradingType]) == "supported" || false;
};
export const isPreview = (exchange, tradingType)=>{
    var _exchange_availability;
    return ((_exchange_availability = exchange.availability) == null ? void 0 : _exchange_availability[tradingType]) == "preview" || false;
};
export const isAccountPortfolioPendingRefresh = (exchangeAccount)=>{
    return exchangeAccount.status == null || exchangeAccount.status == "pending_portfolio_refresh";
};
export const getExchangeLogoUrl = (exchange, colorMode, logoType)=>{
    const exchangeLogoUrl = exchange.logo_url;
    if (!exchangeLogoUrl) {
        return undefined;
    }
    if (logoType) {
        switch(logoType){
            case "global":
                return exchangeLogoUrl.global;
            case "dark":
                return exchangeLogoUrl.dark;
            case "light":
                return exchangeLogoUrl.light;
            case "icon":
                return exchangeLogoUrl.icon;
            default:
                return undefined;
        }
    } else {
        if (exchangeLogoUrl.global) {
            return exchangeLogoUrl.global;
        }
        if (colorMode == "light") {
            return exchangeLogoUrl.light;
        }
        return exchangeLogoUrl.dark;
    }
    return undefined;
};
export const getExchangeIconUrl = (exchange)=>{
    const exchangeMetadata = exchange == null ? void 0 : exchange.metadata;
    return (exchangeMetadata == null ? void 0 : exchangeMetadata.coingecko_url) || undefined;
};
export const getTypedExchange = (exchange)=>{
    return {
        ...exchange,
        availability: exchange.availability != null ? exchange.availability : null,
        premium: exchange.premium != null ? exchange.premium : null
    };
};
export const getTypedExchangeForTradingType = (exchange, tradingType)=>{
    const typedExchange = getTypedExchange(exchange);
    return {
        ...typedExchange,
        isAvailable: isAvailable(typedExchange, tradingType),
        isPreview: isPreview(typedExchange, tradingType),
        isPremium: isPremiumSupported(typedExchange, tradingType),
        isPartiallyPremium: isPremiumPartiallySupported(typedExchange, tradingType)
    };
};
export const sortTypedExchangeForTradingType = (exchanges)=>{
    return exchanges.sort((a, b)=>{
        if (a.isAvailable && b.isAvailable || !a.isAvailable && !b.isAvailable) {
            if (!a.isPremium && b.isPremium) {
                return 1;
            } else if (a.isPremium && !b.isPremium) {
                return -1;
            }
            if (!a.isPartiallyPremium && b.isPartiallyPremium) {
                return 1;
            } else if (a.isPartiallyPremium && !b.isPartiallyPremium) {
                return -1;
            }
            return 0;
        } else if (a.isAvailable && !b.isAvailable) {
            return -1;
        } else {
            return 1;
        }
        return 0;
    });
};
export const getPortfolioItem = (exchangeAccount, coin)=>{
    var _exchangeAccount_portfolio;
    return exchangeAccount == null ? void 0 : (_exchangeAccount_portfolio = exchangeAccount.portfolio) == null ? void 0 : _exchangeAccount_portfolio.find((exchangeAccountPortfolioItem)=>exchangeAccountPortfolioItem.asset == coin);
};
export const fetchSupportedExchanges = async (supabase, limit = 50, supportedTradingTypes, allowFrameworks = true)=>{
    const exchangeRepository = new ExchangeRepository(supabase);
    const exchanges = await exchangeRepository.getAll(limit);
    if (!exchanges) {
        return [];
    }
    return exchanges.filter((exchange)=>supportedTradingTypes.some((supportedTradingType)=>{
            const typedExchange = getTypedExchange(exchange);
            if (!allowFrameworks) {
                const exchangeMetadata = typedExchange == null ? void 0 : typedExchange.metadata;
                if ((exchangeMetadata == null ? void 0 : exchangeMetadata.is_framework) == true) {
                    return false;
                } // else do nothing
            }
            return isAvailable(typedExchange, supportedTradingType);
        }));
};
export const mergeExchangeAccountHistories = (accountsHistory)=>{
    return accountsHistory.reduce((acccountHistoriesByTime, acccountHistoryItem)=>{
        if (!acccountHistoriesByTime[acccountHistoryItem.time] && acccountHistoryItem.value !== null) {
            acccountHistoriesByTime[acccountHistoryItem.time] = {
                value: acccountHistoryItem.value,
                accountIds: [
                    acccountHistoryItem.exchange_account_id
                ]
            };
        }
        if (acccountHistoryItem.value !== null && !acccountHistoriesByTime[acccountHistoryItem.time].accountIds.includes(acccountHistoryItem.exchange_account_id)) {
            acccountHistoriesByTime[acccountHistoryItem.time] = {
                value: (acccountHistoriesByTime[acccountHistoryItem.time].value || 0) + acccountHistoryItem.value,
                accountIds: acccountHistoriesByTime[acccountHistoryItem.time].accountIds.concat([
                    acccountHistoryItem.exchange_account_id
                ])
            };
        }
        return acccountHistoriesByTime;
    }, {});
};
export const concatExchangeAccountHistories = (accountsHistory)=>{
    const latestAccountHistories = new Map();
    // Iterate over each accountHistory from the end to the beginning
    for(let i = accountsHistory.length - 1; i >= 0; i--){
        const accountHistory = accountsHistory[i];
        const { exchange_account_id, value } = accountHistory;
        // Add to the map if the exchange_account_id is not already present
        if (!latestAccountHistories.has(exchange_account_id)) {
            latestAccountHistories.set(exchange_account_id, accountHistory);
        }
    }
    // Sum up the values of the latest account histories
    return Array.from(latestAccountHistories.values()).reduce((accumulator, accountHistory)=>{
        return accumulator + (accountHistory.value || 0);
    }, 0);
};
export const mergeExchangeAccountPortfolios = (accounts)=>{
    return accounts.reduce((cumulativeTotals, account)=>{
        var _this;
        return account && ((_this = Array.isArray(account.portfolio) ? account.portfolio || [] : []) == null ? void 0 : _this.forEach(({ asset, value, total })=>{
            if (asset && asset != AccountPortfolioUSDLikeAssetKey) {
                var _cumulativeTotals_asset, _cumulativeTotals_asset1;
                cumulativeTotals[asset] = {
                    value: (((_cumulativeTotals_asset = cumulativeTotals[asset]) == null ? void 0 : _cumulativeTotals_asset.value) || 0) + (value || 0),
                    quantity: (((_cumulativeTotals_asset1 = cumulativeTotals[asset]) == null ? void 0 : _cumulativeTotals_asset1.quantity) || 0) + (total || 0)
                };
            }
        })), cumulativeTotals;
    }, {});
};
export const hasExchangeAccountPortfolioFunds = (account)=>{
    var _account_portfolio;
    return (account == null ? void 0 : (_account_portfolio = account.portfolio) == null ? void 0 : _account_portfolio.find((portfolioItem)=>portfolioItem.total != 0)) != undefined;
};
export const getExchangeNames = (exchanges, exchangeCredentials, botExchanges)=>{
    return Array.from(new Set(((exchangeCredentials == null ? void 0 : exchangeCredentials.map((credential)=>{
        var _getExchangeFromId;
        return ((_getExchangeFromId = getExchangeFromId(credential.exchange_id || "", exchanges || [])) == null ? void 0 : _getExchangeFromId.name) || "";
    })) || []).concat((botExchanges == null ? void 0 : botExchanges.map((exchangeConfig)=>{
        var _getExchangeFromId;
        return ((_getExchangeFromId = getExchangeFromId(exchangeConfig.exchange_id || "", exchanges || [])) == null ? void 0 : _getExchangeFromId.name) || "";
    })) || [])));
};
export const filterAvailableCredentials = (userExchangesCredentials, runningBotsExchanges, showUsed, allowedExchangeIds, hostingProduct)=>{
    return userExchangesCredentials == null ? void 0 : userExchangesCredentials.filter((exchangeCredential)=>{
        const alreadyUsed = isAlreadyUsedAccount(exchangeCredential, runningBotsExchanges, userExchangesCredentials, hostingProduct) || getAssociatedBot(exchangeCredential, runningBotsExchanges) != undefined;
        return allowedExchangeIds ? allowedExchangeIds.includes(exchangeCredential.exchange_id || "") && exchangeCredential.valid && (showUsed || !alreadyUsed) : true;
    });
};
export const getExchangeAccountAssetsValue = (exchangeAccount, symbolWhitelist, exchangeInternalName, includeUSDLikeAssets = true, includeUSDLikeAsset = false)=>{
    var _exchangeAccount_portfolio;
    return (_exchangeAccount_portfolio = exchangeAccount.portfolio) == null ? void 0 : _exchangeAccount_portfolio.reduce((accumulator, portfolioItem)=>{
        if (symbolWhitelist == undefined || symbolWhitelist.includes(portfolioItem.asset || "") || includeUSDLikeAsset && portfolioItem.asset == AccountPortfolioUSDLikeAssetKey || includeUSDLikeAssets && getExchangeUSDLikeCoins(exchangeInternalName).includes(portfolioItem.asset || "")) {
            return {
                available_value: accumulator.available_value + (portfolioItem.available || 0) * (portfolioItem.value || 0) / (portfolioItem.total || 1),
                total_value: accumulator.total_value + (portfolioItem.value || 0)
            };
        }
        return accumulator;
    }, {
        available_value: 0,
        total_value: 0
    });
};
export const getExchangeUSDLikeCoins = (exchangeInternalName)=>{
    return USD_LIKE_COINS_BY_EXCHANGE[exchangeInternalName || ""] || USD_LIKE_COINS;
};
