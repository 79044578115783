import { getOctoBotCloudAppUrl } from "@astrolab/back/lib/urls"
import { getLocalizedUrl } from "@astrolab/web/lib/locales"

export const getStartedLinkUrl = (
    locale: string, 
    defaultLocale: string,
    isTradingBot: boolean
): string => {
    if(isTradingBot){
        return `/guides/octobot`
    }
    return `${getLocalizedUrl((getOctoBotCloudAppUrl()) || "", locale, defaultLocale)}/register`
}
